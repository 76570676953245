import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Button, Progress} from '@manulife/mux';
import { formatProductList, redirectUrl } from '../../../utils/utility';
import Images from '../../../utils/Images';

const font = "Manulife JH Sans";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    leftSectionContainer: {
        paddingRight: "60px",
        
        [theme.breakpoints.down("sm")]: {
            paddingRight: "0"
        },

    },
    contentContainer: {
        paddingBottom: "32px",
        paddingTop: "0 !important"
    },
    loginHeaderStyles: {
        fontWeight: "600",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "60px",
        fontStyle: "normal",
        color: "#202336",
        margin: 0,
        paddingBottom: "16px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "38px",
            lineHeight: "50px",
        }
    },
    loginBodyStyles: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "24px",
        lineHeight: "32px",
        fontStyle: "normal",
        color: "#202336",
        margin: 0,
        paddingTop: "16px",   
        paddingBottom: "20px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "22px",
        }
    },
    buttonTextStyles: {
        fontWeight: "700",
        fontFamily: font,
        fontSize: "19px",
        lineHeight: "24px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFFFFF",
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom:"20px",
        display:"inline-block",
        marginLeft:"8px"
    },
    inlineDisplay: {
        display: "flex",
        height:"100%",
        marginLeft:"8px"
    },
    inlineDisplay2:{
        display: "inline-block",
        marginTop: "10px",
        marginBottom:"10px",
    },
    buttonContainer:{
        display:"flex",
        paddingTop: "32px !important",
        "& button": {
            height: "60px",
            marginTop: "-7px",
            
            [theme.breakpoints.only("xs")]: {
                width: "100%",
            }
        }
    },
    userNotesStyles: {
        paddingTop: "20px",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "24px",
        fontStyle: "normal",
        color: "#282B3E",
        margin: 0,

        "& ul": {
            margin: 0,
            paddingTop: "8px"
        },
        
        "& li": {
            fontFamily: font,
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            fontStyle: "normal",
            color: "#202336",
            margin: 0,
            paddingBottom: "8px"
        }
    },
    productListTitle: {
        fontFamily: font,
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        fontStyle: "normal",
        color: "#202336",
        margin: 0,
        paddingBottom: "8px"
    },

    qrcodeRootContainer: {
        width: "auto",
        minWidth: "210px",
        backgroundColor: "#FAFAFA",
        padding: "32px",

        [theme.breakpoints.down("sm")]: {
            marginTop: "40px",
        }
    },    
    qrCodeTitle: {
        display: "block",
        fontFamily: font,
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "25px",
        color: "#202336",
        marginBottom: "16px"
    },    
    qrCodeBodyText1: {
        display: "block",
        fontFamily: font,
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#282B3E",
        marginBottom: "32px"
    },  
    qrCodeImage: {
        display: "block",
        width: "75%",
        maxWidth: "200px",
        minWidth: "150px",
        marginBottom: "32px",

        [theme.breakpoints.down("xs")]: {
            width: "200px",
        }
    },
    storeImage: {
        display: "block",
        height: "47px",
        marginBottom: "16px",
        cursor: "pointer",
        [theme.breakpoints.down("1100")]: {
            height: "42px",
        }
    },
    qrCodeBodyText2: {
        display: "block",
        fontFamily: font,
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#282B3E",
        marginBottom: "16px"
    },
    disclosureContainer: {
        marginTop: "40px"
    },
    disclosureText: {
        fontFamily: font,
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "21px",
        textAlign: "left",
        color: "#202336"

    }
}))

const CongratulationsPage = props => {

    const classes = useStyles()
    const [productList, setProductList] = useState([]);

    useEffect(()=> {
        props.setSessionTimeoutEnable(true)
    }, [])

    useEffect(()=> {
        let tempProductList = formatProductList(props.productList);
        setProductList(tempProductList);
    }, [props.productList])

    const QRCodeComponent = ()=> {
        return <div className={classes.qrcodeRootContainer}>
            <span className={classes.qrCodeTitle}>Download John Hancock’s retirement app</span>
            <span className={classes.qrCodeBodyText1}>On your desktop? Scan the QR code using your mobile device's camera.</span>
            <img className={classes.qrCodeImage} src={Images.QR_MOBILE_APP} alt='Download John Hancock’s retirement app'/>
            <span className={classes.qrCodeBodyText2}>On your phone? Tap your app store badge to download.</span>
            <a href="https://apps.apple.com/us/app/john-hancock-retirement/id1558846059"><img className={classes.storeImage} src={Images.APPSTORE_IMG} alt='Apple Store'/></a>
            <a href="https://play.google.com/store/apps/details?id=com.johnhancock.myplan"><img className={classes.storeImage} src={Images.PLAYSTORE_IMG} alt='Play Store'/></a>
        </div>
    }

    return (
        <Grid container justifyContent={"space-between"} className={classes.root}>
            <Grid item xs={12} sm={10} md={8} className={classes.leftSectionContainer}>

                <Grid item xs={12} className={classes.contentContainer}>
                    <h1 className={classes.loginHeaderStyles}>
                        Congratulations! Your registration is complete.
                    </h1>
                    <p className={classes.loginBodyStyles}>
                        Log in to see your account details.
                    </p>
                    
                    {
                        productList.length > 0 &&
                        <Grid item xs={12} md={12} className={classes.userNotesStyles}>
                            <div className={classes.productListTitle}>Remember, your username signs you in for:</div>
                            <ul>
                                {
                                    productList.map(item=> <li>{item}</li> )
                                }
                            </ul>
                        </Grid>
                    }
                </Grid>

                <Grid item xs={12} className={classes.buttonContainer}>
                    <Button
                        name="BackToLoginButton"
                        id="BackToLoginButton"
                        disabled={props.isAwaitingApiCall}
                        onClick={() => props.postCall()}><div className={classes.buttonTextStyles}>My account</div></Button>
                    {props.isAwaitingApiCall &&  <div className={classes.inlineDisplay}>
                        <div className={classes.inlineDisplay2}>

                            <Progress isFloating={false} customStyle={{
                                spinnerStyle: {
                                    position: "relative",
                                    height:"40px",
                                    width:"40px"
                                }
                            }} />
                        </div>
                        <p className={classes.loadingText}>Please wait while we process your information</p>
                    </div>
                    }
                
                </Grid>

                <Grid item xs={12} className={classes.disclosureContainer}>
                    <span className={classes.disclosureText}>Access and use of this website is for authorized users only. If you have any questions regarding your account or registration, please contact a John Hancock representative at 888-888-8888.</span>
                </Grid>

            </Grid>

            <Grid item spacing={0} xs={12} sm={6} md={4}>
                <QRCodeComponent/>
            </Grid>
        </Grid>
    );
};

export default CongratulationsPage;