import axios from 'axios';

const ajaxVerifyAge = (dob, errorState, setErrorState, setAwaitingAPI) => {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true
    };
    setAwaitingAPI(true)
    axios.post(
        process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/verifyage` : 'http://localhost:8080/verifyAge',
        {
            "dateofbirth": dob
        },
        { headers }).then(response => {
            setAwaitingAPI(false)
            if (response.data.code === "1002") {
                setErrorState({ ...errorState, dob: { id: 'dob', errMsg: '', isError: false } })
            } else if (response.data.code === "9063") {
                setErrorState({ ...errorState, dob: { id: 'dob', errMsg: 'Error invalid input', isError: true } })
            }
        }).catch(error => {
            console.log("Error ========>", error);
            setAwaitingAPI(false)
        })

}

export default ajaxVerifyAge;
