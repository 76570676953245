import React from 'react';
import { Grid } from '@material-ui/core';
import RegistrationSteps from '../RegistrationSteps';
import PageHeader from '../PageHeader';
import Disclosures from "../Disclosures";
import SignInRightSection from '../InformativeRightSection/SignInRightSection';
import JsonConfig from '../../utils/jsonConfig.json';

const RegistrationColumnLayout = ({
  appId,
  step,
  page,
  text,
  disclosure,
  subText = null,
  children,
  errorType,
  maintenanceDetails,
  isAuthenticationFlow
}) => {
  return (
    <Grid container>
      <Grid item xs={12} md={9}>
          
          {/* Header Start */}
          <Grid item xs={12} md={10}>
            <PageHeader step={step} page={page} appId={appId} errorType={errorType} maintenanceDetails={maintenanceDetails} text={text} subText={subText} />
          </Grid>
          {/* Header End */}
            
          <Grid container>
            <Grid item xs={12} md={4}>
              <RegistrationSteps step={step} isAuthenticationFlow={isAuthenticationFlow}/>
            </Grid>
            <Grid item xs={12} md={8}>
              {children}
            </Grid>
          </Grid>
      </Grid>
      
      {
        appId === JsonConfig.app_id.RPS &&
        <Grid item xs={12} sm={8} md={3}>
          <SignInRightSection appId={appId}/>
        </Grid>
      }

      <Grid item xs={12} md={12}>
          {disclosure && <Disclosures />}
      </Grid>
    </Grid>
  );
};

export default RegistrationColumnLayout;
