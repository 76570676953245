import React from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from '@material-ui/core'
import { ReactComponent as FooterLogo } from '../../../images/footer-logo.svg';
import {getQueryParams} from "../../../utils";
import JsonConfig from '../../../utils/jsonConfig.json';
import { Phone } from '@manulife/mux-cds-icons';



const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0, // for the sticky footer
        width: "100%",
        background: "#fff",
        marginTop: "100px",
    },
    footer: {
        width: "100%",
        background: "#2F3242",
    },
    bodyContainer: {
        padding: "32px 40px",
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "20px",
            paddingRight: "20px"
         }
    },
    logoStyles: {
        textAlign: "left",

        [theme.breakpoints.down("sm")]: {
           marginBottom: "24px"
        }
    },
    bodyStyles: {
        padding: "0 30px 30px",
        [theme.breakpoints.up("sm")]: {
            padding: 0
        }
    },
    labelStyles: {
        margin: 0,
        fontWeight: "400",
        fontFamily: "Manulife JH Sans",
        fontSize: "13px",
        display: "block",
        color: "#FFFFFF",
        lineHeight: "16px",
        opacity: "0.8",
        "& p": {
            marginBottom: "16px"
        }
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    linkStyles: {
        color: "#FFFFFF",
        textDecoration: "none",
        fontFamily: "Manulife JH Sans",
        fontSize: "14px",
        whiteSpace: "nowrap",
        '&:hover': {
            textDecoration: "underline"
        }
    },
    linkListContainer: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "right",
        listStyle: "none",
        padding: 0,
        margin: 0,
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",

        
        [theme.breakpoints.down("sm")]: {
            justifyContent: "left"
        },

        [theme.breakpoints.down("xs")]: {
            justifyContent: "space-between",
            padding: "16px 0 16px 0",
        },

        "& li": {
            [theme.breakpoints.down("xs")]: {
                paddingTop: "16px",
                paddingBottom: "16px"
            },
            [theme.breakpoints.down("sm")]: {
                paddingTop: "16px",
                paddingBottom: "16px"
            },
            "& a": {
                marginRight: "32px"
            },
            '&:last-child:after': {
            }
        }
    },
    contactUsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        listStyle: "none",
        padding: 0,
        margin: 0,
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px"        
    },
    newLine: {
        display: "block"
    },
    footerMenuContainer: {
        padding: "32px 40px 32px 40px",
        borderBottom: "2px solid #34384B",

        [theme.breakpoints.down("xs")]: {
            padding: "32px 20px 32px 20px",
        }
    }
}));

const RPSFooter = (props) => {
    const classes = useStyles();

    let { appId } = getQueryParams();

    return (
        <Grid data-testid="footer-root" className={classes.root}>
            <Grid className={classes.footer}>
                {
                    appId !== JsonConfig.app_id.DTC && process.env.REACT_APP_ENABLE_CHATBOT === "enabled" && 
                        <div className="jh-webchat" id="chatBotDiv" />
                }
                <Grid container justifyContent='space-between' className={classes.footerMenuContainer}>
                    <Grid item xs={12} sm={12} md={2}>
                        <span style={{justifyContent: "left"}} className={classes.contactUsContainer}>
                            <Phone color="#fff" style={{ width: "20px", height: "20px", marginRight: "12px"}} /><a className={classes.linkStyles} rel="noopener noreferrer" href="https://myplan.johnhancock.com/ContactUs" target="_blank">Contact us</a>
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <ul className={classes.linkListContainer}>
                            <li>
                                <a className={classes.linkStyles} rel="noopener noreferrer" href="http://www.johnhancock.com/legal.html" target="_blank">Legal</a>
                            </li>
                            <li>
                                <a className={classes.linkStyles} rel="noopener noreferrer" href="http://www.johnhancock.com/privacysecurity.html" target="_blank">Privacy &amp; Security</a>
                            </li>
                            <li>
                                <a className={classes.linkStyles} rel="noopener noreferrer" href="http://www.johnhancock.com/accessibility.html" target="_blank">Accessibility</a>
                            </li>
                            <li>
                                <a className={classes.linkStyles} rel="noopener noreferrer" href="https://www.johnhancock.com/individual.html" target="_blank">Corporate Website</a>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid justifyContent='space-between' container spacing={0} className={classes.bodyContainer}>
                    <Grid item md={4} xs={12} className={classes.logoStyles}>
                        <FooterLogo/>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        {
                            appId &&
                            <div className={classes.labelStyles}>
                                <p>John Hancock and the plan are not affiliated and neither is responsible for the liabilities of the other. </p>
                                <p>The content of this website is for general information only and is believed to be accurate and reliable as of posting date but may be subject to change. John Hancock does not provide investment, tax, or legal advice. Please consult your own independent advisor as to any investment, tax, or legal statements made herein.</p>
                                <p>All plan information is confidential and you agree to safeguard and protect such information in accordance with the standards required by law and use such information only for authorized plan administration purposes.</p>
                                <p>John Hancock Retirement Plan Services LLC provides administrative and/or recordkeeping services to sponsors or administrators of retirement plans through an open-architecture platform. John Hancock Trust Company LLC, a New Hampshire non-depository trust company, provides trust and custodial services to such plans, offers an Individual Retirement Accounts product, and maintains specific Collective Investment Trusts. Group annuity contracts and recordkeeping agreements are issued by John Hancock Life Insurance Company (U.S.A.), Boston, MA (not licensed in NY), and John Hancock Life Insurance Company of New York, Valhalla, NY. Product features and availability may differ by state. All entities do business under certain instances using the John Hancock brand name. Each entity makes available a platform of investment alternatives to sponsors or administrators of retirement plans without regard to the individualized needs of any plan. Unless otherwise specifically stated in writing, each entity does not, and is not undertaking to, provide impartial investment advice or give advice in a fiduciary capacity. Securities are offered through John Hancock Distributors LLC, member FINRA, SIPC.</p>
                                <p>NOT FDIC INSURED. MAY LOSE VALUE. NOT BANK GUARANTEED</p>
                                <p>© 2024 All rights reserved.</p>
                                <p>MGTS-P40162-GE</p>
                            </div>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default RPSFooter;
