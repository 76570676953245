import { makeStyles } from "@material-ui/core";
import { Button } from '@manulife/mux';
import { redirectUrl } from "../../utils/utility";


const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles((theme) => ({
    rightSectionRoot: {
        padding: "32px",
        backgroundColor: "#FAFAFA",
        [theme.breakpoints.down("sm")]: {
            marginTop: "20px"
        }
    },
    alreadyRegisteredText: {
        display: "block",
        fontFamily: font,
        fontSize: "19px",
        fontWeight: "600",
        lineHeight: "24px",
        marginBottom: "20px",
        color: "#202336"
    },

    signInButtonContainer: {

        "& button": {
            height: "56px",
            width: "73%",
            minWidth: "150px",
            maxWidth: "210px",

            fontFamily: font,
            fontSize: "19px",
            fontWeight: "600",
            lineHeight: "24px",
            color: "#282B3E",

            [theme.breakpoints.only("xs")]: {
                width: "100%",
                maxWidth: "100%"
            }
        }
    },
}));

const SignInRightSection = (props) => {
    const classes = useStyles();

    function redirectToLogin(){
        redirectUrl(process.env.REACT_APP_LOGIN_URL_RPS);
    }

    return  <div className={classes.rightSectionRoot}>
        <span className={classes.alreadyRegisteredText}>Already Registered?</span>
        <div className={classes.signInButtonContainer}>
            <Button
                variant="secondary"
                name="BackToLoginButton"
                id="BackToLoginButton"
                onClick={e => redirectToLogin()}>Sign In</Button> 
        </div>
    </div>
}

export default SignInRightSection;