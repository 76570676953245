import React, { useEffect, useState } from 'react';
import { Eye1, Eye2 } from '@manulife/mux-cds-icons';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles"
import {TextInput, INPUT_VARIANT, hasErrors, Icon, I} from '@manulife/mux';
import { Checkbox, Dropdown, DROPDOWN_VARIANT, Button, BUTTON_VARIANT, RadioGroup, ActionButton } from '@manulife/mux';
import moment from "moment";
import { validate } from '../../components/PageBody/validate'
import { ajaxEmail } from '../../api';
import { Progress } from '@manulife/mux';
import { Modal, BREAKPOINTS } from '@manulife/mux';
import { ConfirmationModalPage } from '..';
import EncounteredError from '../../components/EncounteredError';
import JsonConfig from '../../utils/jsonConfig.json';
import Utility, { doNothing } from '../../utils/utility'
import { Tooltip } from '@manulife/mux';
import { Info1 } from '@manulife/mux-cds-icons';
import { ReactComponent as LeafLogo } from '../../images/Leaf.svg';
import { ElectronicTCModalPage } from '..';
import ajaxVerifyAge from '../../api/ajaxVerifyAge';
import useAdobeDataLayer from '../../hooks/useAdobeDataLayer';
import InputError from '../../components/InputError/InputError';



const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const displayStyle = "inline-block";
const gridSeparation1 = "gridSeparation1";
const myinput = "myinput";
const ENCOUNTERED_ERROR = "Encountered Error";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "14px",
        color: "#5E6073",
        lineHeight: "20px"
    },
   
    goPaperlesshead:{
        lineHeight: "24px"       
    },
    flexDisplay: {
        display: "flex"
    },  

    goPaperLessHeading: {
        fontSize: "26px",
        fontFamily: "Manulife JH Sans",
        fontWeight: "600",
        lineHeight: "36px",      
        color: "#202336",
        letterSpacing: "0px",
        textAlign: "left"
    },
    goPaperlessContentBody: {
       
        letterSpacing: "0px",
        textAlign: "left"
    },
    goPaperLessSubHeadingBody:{
       marginTop: "-2%",
       lineHeight: "36px"

    },
    logoStyles: {
        textAlign: "center",
        "& svg": {
            maxWidth: "100%",
            marginTop: "24px",
            position: "relative",
            top: "5px"
        },
        [theme.breakpoints.up("sm")]: {
            paddingRight: "10px",
            textAlign: "left"
        }
    },
    goPaperLessSubHeading: {
        fontSize: "18px",
        fontFamily: "Manulife JH Sans",
        fontWeight: "600",
        lineHeight: "24px",       
        color: "#000000",
        letterSpacing: "0px",
        textAlign: "left"
    },
    goPaperLessContent: {
        fontSize: "16px",
        fontFamily: "Manulife JH Sans",
        fontWeight: "400",
        lineHeight: "24px",     
       
        color: "#000000",
        letterSpacing: "0px",
        textAlign: "left"
    },
   
    tnCblock: {
        gap: "10px"
    },
    termsandconditionsHeading: {
        fontSize: "26px",
        fontFamily: "Manulife JH Sans",
        fontWeight: "600",
        lineHeight: "36px",
        color: "#202336",
        letterSpacing: "0px",
        textAlign: "left"
    },
    gridAlignment: {
        display: "flex",
        marginRight: "60px",
        // alignSelf: "stretch"
    },
    gridLowMargin: {
        margin: "0px",
        paddingBottom: "5px !important"
    },
    gridNoMargin: {
        margin: "0px",
        paddingBottom: "0px !important"
    },
    InLine: {
        display: displayStyle
    },
    InLineLabel: {
        display: displayStyle,
        fontWeight: "normal",
        fontFamily: font,
        fontSize: "16px",
        paddingRight: "5px",
        color: "#282B3E"
    },
    blueUnderline: {
        textDecoration: "none",
        borderBottom: "2.0px solid #0000C1",
        color: "black",
        fontWeight: "600"
    },
    signInUnderline: {
        fontFamily: font,
        fontSize: "16px",
        color: "black",
        textDecorationColor: "#0000C1",
        lineHeight: "20px"
    },
    ctaWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingBottom: "40px",
        marginTop: "20px",
        "& a": {
            marginBottom: "1rem"
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center",
            "& a": {
                flexShrink: 0,
                marginRight: "1rem",
                marginBottom: 0
            }
        },
        "& button": {
            width: "150px",
            minWidth: "145px",
            height: "60px",
            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    underlineWrapper: {
        display: "flex",
        paddingTop: "25px"
    },
    errorIcon: {
        float: "left",
        height: "17px",
        width: "17px",
        marginTop: "2px",
        color: "black !important",
        content: " ",
        background: 'url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAEZElEQVQ4jXWUCWxUVRSG/3vfmzcLM12gDZ0RKUNiNAQbjVXikghJkYSYKIWgIotaEloVMZLYSCXKkhiNxSiQikhYWg0iS9oqlIJABFkLaUu1xJbSTjtbO1tnOst7b+Zdc1+gQtqe5EvuknNyzrn/PSTu92IiYwCowQA1EjJE/2ndMnVB6UZQqrJ0egKPu6Zk0hOjqmCMwXf+dNnRbLDBy+ff4XtFUaGk0xNCM7EoJoIpMjQw9P+8ZytPu2//zq08e0IJWFoFz3g8xHhv15jseQAGBrPjYbgbfvkkcK65IGuWE0NnTtgHGg5V2heWfpnyDIAQMsaVGxWtWRgP45SpYJmM1XPowGYiGpD9xJyYIJngqq3ZLIcDVmoy6z0fDyT83rEM+qAxhq5d22p4L6+WlXo1xpxXVy/xHLGBde3+ZofeWzkFJT4yBsiJ+IPER/SAiYB/1pm5s1njQxJzN9Wv5UHcTfXrGuwiOzt3NkvFoo/xs/Q40JTPjQcI+KGlVbjqftgWutSBGWUVf1KzWb2w5KU2wWxRZ5S9ez5wsQN39m7/jpcqBwNIDfoegApGE0aRjJDMkxBsuVjSu7dmgfXRAuSXvLx2uK0lN3L1clG0sz3LsXRVhe2RfLj27JwfamuZZzAYIBACgdJR9FI5GcZ0TXJaq96/eUgA69hSeYVr0t3c+Nzt2poq7x8nnuXl3dy0/tqvAljrhvfa2X1+90C077bOcG+33njvmROrfp9pY6eecbKYp7841N2JGx+v2XeyqCB8/cO394343EiEAsXNxdNZY6GZ+S+dW8YDJSKhUag4yQrRYoVxch4IFUTXge+3J90xOCvWN1rt01ri3bcATctWhyM5RKDZkY5WmHOntDjL1zemfEn0/vjtjgxjomiygAoCKBVABYsNoi0HRls23IdrN3mP19vyXnwSzuVrPlAiIWTiCQiSSRYsFhDJJGtyCmAMzpXl6/LnPQVv/bHcgSM/fW4wGnV9C5ZJoNRogsEoQZVlh6tu1wamANNXlH9NRbE32n0LSiwCIhkAQkANEpRwEOGOVggG6U7h8jXVmqp/36p0WrUL/DfxbGW/B0zL6BIaOnsFjtJX49OWlW2MD/rANAZjzmTIg97cRE8Q8pB/smC1Qo2PYMTrhmPxik8di15JDJ2+jL7aXdX8M/E7JMMBhDvb5jQXF7KmInsm2H59PldD+Pa/GPF7ER1wYaCp/rW/v6g67j7ZsDTa34e4z4twT5eumEDbtZKmxwu0U08XsmHXnWIlmQThL9extfJCz46vnncsfiNpX/Rmtez3SIJkNDFNY0SUqDQlLyzaskzpWDSlBIdyWVrVCBWIpshJ41S74jlaV+k5dlCaWf7RX7M/q36B9P92ePWNsiW7zXYHCO9ZJPj/lOBjjvDHz+h7viZUuHd11wiknFx9FCbdHhQfPP6WGO/qfD2/ZOGwZbozzFSZsIxGQMhdv3vuPBofc1zcoyf3xSWgksSS/X050faWlf8Ba1jnhwXMkasAAAAASUVORK5CYII=") no-repeat center bottom !important',

    },
    showIcon: {
        display: displayStyle,
        marginLeft: "-25px",
        marginTop: "-20px"
    },
    errorLabelStyle: {
        color: "#A00E18 !important",
        fontWeight: "300px",
        marginLeft: "5px"
    },
    errorWrapper: {
        paddingBottom: "40px"
    },
    errorMessage: {
        display: "flex",
        color: "#A00E18",
        marginTop: "0.5em",
        "&:before": {
            content: "''",
            display: displayStyle,
            flexShrink: 0,
            width: "29px",
            height: "17px",
            marginTop: "2px",
            background: 'url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAEZElEQVQ4jXWUCWxUVRSG/3vfmzcLM12gDZ0RKUNiNAQbjVXikghJkYSYKIWgIotaEloVMZLYSCXKkhiNxSiQikhYWg0iS9oqlIJABFkLaUu1xJbSTjtbO1tnOst7b+Zdc1+gQtqe5EvuknNyzrn/PSTu92IiYwCowQA1EjJE/2ndMnVB6UZQqrJ0egKPu6Zk0hOjqmCMwXf+dNnRbLDBy+ff4XtFUaGk0xNCM7EoJoIpMjQw9P+8ZytPu2//zq08e0IJWFoFz3g8xHhv15jseQAGBrPjYbgbfvkkcK65IGuWE0NnTtgHGg5V2heWfpnyDIAQMsaVGxWtWRgP45SpYJmM1XPowGYiGpD9xJyYIJngqq3ZLIcDVmoy6z0fDyT83rEM+qAxhq5d22p4L6+WlXo1xpxXVy/xHLGBde3+ZofeWzkFJT4yBsiJ+IPER/SAiYB/1pm5s1njQxJzN9Wv5UHcTfXrGuwiOzt3NkvFoo/xs/Q40JTPjQcI+KGlVbjqftgWutSBGWUVf1KzWb2w5KU2wWxRZ5S9ez5wsQN39m7/jpcqBwNIDfoegApGE0aRjJDMkxBsuVjSu7dmgfXRAuSXvLx2uK0lN3L1clG0sz3LsXRVhe2RfLj27JwfamuZZzAYIBACgdJR9FI5GcZ0TXJaq96/eUgA69hSeYVr0t3c+Nzt2poq7x8nnuXl3dy0/tqvAljrhvfa2X1+90C077bOcG+33njvmROrfp9pY6eecbKYp7841N2JGx+v2XeyqCB8/cO394343EiEAsXNxdNZY6GZ+S+dW8YDJSKhUag4yQrRYoVxch4IFUTXge+3J90xOCvWN1rt01ri3bcATctWhyM5RKDZkY5WmHOntDjL1zemfEn0/vjtjgxjomiygAoCKBVABYsNoi0HRls23IdrN3mP19vyXnwSzuVrPlAiIWTiCQiSSRYsFhDJJGtyCmAMzpXl6/LnPQVv/bHcgSM/fW4wGnV9C5ZJoNRogsEoQZVlh6tu1wamANNXlH9NRbE32n0LSiwCIhkAQkANEpRwEOGOVggG6U7h8jXVmqp/36p0WrUL/DfxbGW/B0zL6BIaOnsFjtJX49OWlW2MD/rANAZjzmTIg97cRE8Q8pB/smC1Qo2PYMTrhmPxik8di15JDJ2+jL7aXdX8M/E7JMMBhDvb5jQXF7KmInsm2H59PldD+Pa/GPF7ER1wYaCp/rW/v6g67j7ZsDTa34e4z4twT5eumEDbtZKmxwu0U08XsmHXnWIlmQThL9extfJCz46vnncsfiNpX/Rmtez3SIJkNDFNY0SUqDQlLyzaskzpWDSlBIdyWVrVCBWIpshJ41S74jlaV+k5dlCaWf7RX7M/q36B9P92ePWNsiW7zXYHCO9ZJPj/lOBjjvDHz+h7viZUuHd11wiknFx9FCbdHhQfPP6WGO/qfD2/ZOGwZbozzFSZsIxGQMhdv3vuPBofc1zcoyf3xSWgksSS/X050faWlf8Ba1jnhwXMkasAAAAASUVORK5CYII=") no-repeat center bottom !important',
        }
    },
    dateOfBirth: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",


    },
    errorSSNLabel: {
        marginLeft: "39px",
        color: "rgb(219, 31, 0)",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        margin: "0.5em 0px 0px"
    },
    termsAndConditions: {
        display: "flex",
        color: "#282B3E",
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "26px",
        alignItems: "center"
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
        marginLeft: "8px"
    },
    inlineDisplay2: {
        display: displayStyle,
        marginTop: "10px",
        marginBottom: "10px",
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: displayStyle,
        marginLeft: "8px"

    },
    buttonWrapper: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.only("xs")]: {
            flexDirection: "column",
            width: "100%"
        },
        "& button": {
            display: "flex",
            marginBottom: "1rem",
            height: "60px",
            [theme.breakpoints.up("sm")]: {
                marginBottom: 0,
                marginLeft: "1.5rem",
                "&:first-child": {
                    marginLeft: 0,
                    color: "#282B3E"
                },
                "&:first-child:hover": {
                    marginLeft: 0,
                    color: "#fff"
                }
            }
        }
    },
    textStyle: {
        fontFamily: font,
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "22px",
        color: "#000000"
    },
    confirmTextStyle: {
        fontFamily: font,
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "22px"
    },    
    errorLabel: {
        color: "rgb(219, 31, 0)",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400"
    },
}))
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const buildDropDownMonth = () => {
    let monthObj = []
    for (let i = 0; i < months.length; i++) {
        monthObj.push({ value: (i + 1).toString(), label: months[i].toString() })
    }
    return monthObj
}
const buildDropDownYear = () => {
    let yearObj = []
    let yearNow = parseInt(moment().format('YYYY'));
    let startYear = 1899
    let length = yearNow - startYear;
    for (let i = 0; i < length; i++) {
        let year = yearNow - i
        yearObj.push({ value: year.toString(), label: year.toString() })
    }
    return yearObj
}

const buildDropDownDay = (month, year) => {
    let dayObj = []
    let monthString = month == null ? moment().daysInMonth() : [parseInt(month) < 10 ? "0" + month : month]
    let yearString = month == null ? null : year;
    let days = moment(yearString + "-" + monthString, "YYYY-MM").daysInMonth()
    for (let i = 0; i < days; i++) {
        dayObj.push({ value: (i + 1).toString(), label: (i + 1).toString() })
    }
    return dayObj
}

const tooltipTextElectronicDelivery = "Not all communications, notices and disclosures, are currently available to be delivered electronically, so you may still receive some paper mail until enhancements are made."
const UserInfoPage = props => {

    const {setPaperlessTCModalOpen, setSessionTimeoutEnable, setModalOpen, onClick, errorState, 
        appId, verifyUser, setFormValues, errorsFound, formValues, setErrorState, setSystemErrorCount,
        systemErrorCount, setIsAwaitingApiCall, isAwaitingApiCall, isModalOpen, isPaperlessTCModalOpen} = props;
    const adobeDataLayer = useAdobeDataLayer();

    const [isUnderstand, setisUnderstand] = useState(false)
    const [isEmailAjaxCallFail, setEmailAjaxCallFail] = useState(false)
    const [isEmailLoading, setEmailLoading] = useState(false)

    const checkisUnderstand = (value) => {      
        setisUnderstand(true); 
        setPaperlessTCModalOpen(false);     
    };

    useEffect(() => {
        adobeDataLayer.pageLoad('step1');
        adobeDataLayer.registrationStart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(()=> {
        setSessionTimeoutEnable(true);
        Utility.scrollToTop();
    }, [])

   
    const modalOpen = (val) => {
        setModalOpen(true);
    };

    const modalClose = () => {
        !isAwaitingApiCall && setModalOpen(false);
    };

    const paperlessModalOpen = (event) => { 
        event && event.preventDefault()     
        setPaperlessTCModalOpen(true);
    };

    const paperlessModalClose = () => {
        setPaperlessTCModalOpen(false);
    };

    const modalEdit = () => {
        setModalOpen(false);
        onClick(0);
    }

    const modalConfirm = () => {
        verifyUser();
    }

     //check if appid is Life or LTC or PDCO
     let checkAppId = false;
     if(JsonConfig.app_id.LIFE === appId || JsonConfig.app_id.LTC === appId || JsonConfig.app_id.PDCO === appId){
         checkAppId = true;
     }
  

    const validateAndConfirm = (formValues, errorState, setErrorState) => {
        let temperrors = validate(formValues, '', errorState, true, 1, null, false);
        temperrors = validateErrors(temperrors);
        setErrorState({...temperrors});
        setFormValues({...formValues, userEnteredPhoneNumber: formValues.phoneNumber, phoneNumber: formValues.phoneNumber.replace(/\D/g, '')});
        if(!errorsFound(temperrors)) {
            modalOpen();
        }                    
    }

    function validateErrors(errorState){
        if (isEmailAjaxCallFail) {
            errorState.emailAddress = { id: 'emailAddress', errMsg: ENCOUNTERED_ERROR, isError: true };
        }
        return errorState;
    }

    const classes = useStyles();
    const [showSignIn, setShowSignIn] = useState(false);
    
    const changeHandler = (name, value) => {
        //code added to check for checkbox for gopaperless
        let newFormValues = null;
        if (name === 'goPaperless') {
            newFormValues = { ...formValues, [name]: value, "notGoPaperless": false };
        }
        else if (name === 'notGoPaperless') {
            newFormValues = { ...formValues, [name]: value, "goPaperless": false };
        }
        else {
            newFormValues = { ...formValues, [name]: value };
        }
        setFormValues(newFormValues)
        setErrorState(validate(newFormValues, name, errorState, false, 1, null, true));
    }

    const [allValues, setAllValues] = useState({
        ssnVisible: false,
    });
    const handleShow = (name) => {
        setAllValues({ ...allValues, [name]: !allValues[name] })
    }

    function onEmailAjaxCallResponse(response, error){
        setEmailLoading(false);
        if(response){
            if (response.data.code === "200") {
                if (errorState.emailAddress.errMsg === "Email address is already taken") {
                    setErrorState({ ...errorState, emailAddress: { id: 'emailAddress', errMsg: '', isError: false } })
                }
                if(errorState.emailAddress.errMsg === ENCOUNTERED_ERROR) {
                    setErrorState({ ...errorState, emailAddress: { id: 'emailAddress', errMsg: '', isError: false } })
                }
                setEmailAjaxCallFail(false);
                setShowSignIn(false)
            } else if (response.data.code === "9001" || response.data.code === "9014") {
                setErrorState({ ...errorState, emailAddress: { id: 'emailAddress', errMsg: ENCOUNTERED_ERROR, isError: true } })
                setEmailAjaxCallFail(true);
                setSystemErrorCount(systemErrorCount + 1);
            } else {
                if(formValues.emailAddress !== "") {
                    setErrorState({ ...errorState, emailAddress: { id: 'emailAddress', errMsg: 'Email address is already taken', isError: true } })
                    setShowSignIn(true)
                    setEmailAjaxCallFail(false);
                }
            }
        }
    }

    function verifyEmail(){
        if(!(errorState.firstName.isError || errorState.lastName.isError || errorState.emailAddress.isError || formValues.emailAddress == '')){
            setEmailLoading(true);
            ajaxEmail(formValues.emailAddress, formValues.firstName.trim(), formValues.lastName.trim(), onEmailAjaxCallResponse);
        } else{
            setErrorState({...errorState, emailAddress: { ...errorState.emailAddress, warning: false }})
        }
    }

    useEffect(()=> {    
        let timeOut = null;
        if(appId === JsonConfig.app_id.RPS && formValues.day && formValues.month && formValues.year){
            timeOut = setTimeout(() => {
                // 30/12/1965 -> day/month/year
                let dob = formValues.day +"/"+ formValues.month+"/" +formValues.year;
                ajaxVerifyAge(dob, errorState, setErrorState, setIsAwaitingApiCall);
            }, 1000);     
        }
        return ()=> {
            timeOut !== null && clearTimeout(timeOut);
        }
    }, [appId, formValues.day, formValues.month, formValues.year]);


    let loginUrlVariable = "REACT_APP_LOGIN_URL_"+JsonConfig.login_url_postfix[appId];
    let redirectUrl = process.env[loginUrlVariable] || JsonConfig.static_redirect_url.default;

    return (
        <div data-testid="Userinfo-root" className={classes.root}>
            <Grid container spacing={0}>
                {
                    systemErrorCount > 0 &&
                        <EncounteredError systemErrorCount={systemErrorCount}/>
                }
                <Grid item md={9} xs={12} className={gridSeparation1+" "+myinput+(errorState.firstName.isError && !errorState.firstName.warning ? " error" : "")}>
                    <label className={classes.labelStyle}>
                        First name
                    </label>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id='firstName'
                        data-testid="UserInfo-firstName"
                        placeholder="Enter first name"
                        maxLength={50}
                        onKeyDown={doNothing}
                        value={formValues.firstName}
                        onChange={e => changeHandler("firstName", e)}
                        onBlur={()=> setErrorState({...errorState, firstName: { ...errorState.firstName, warning: false }})}
                        callouts={true}
                        customStyle={{
                            rootStyle: {
                                width: "100%",
                            },
                            inputStyle: {
                                padding: '0px'

                            }
                        }} name="firstName" />                        
                    {errorState.firstName.isError && <InputError errorState={errorState} inputId="firstName" showIcon={true}/>}
                </Grid>
                <Grid item md={9} xs={12} className={gridSeparation1+" "+myinput+(errorState.lastName.isError && !errorState.lastName.warning ? " error" : "")}>
                    <label className={classes.labelStyle} >
                        Last name
                    </label>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id='lastName'
                        data-testid="UserInfo-lastName"
                        placeholder="Enter last name"
                        required={true}
                        maxLength={50}
                        value={formValues.lastName}
                        onKeyDown={doNothing}
                        onChange={e => changeHandler("lastName", e)}
                        onBlur={()=> setErrorState({...errorState, lastName: { ...errorState.lastName, warning: false }})}
                        customStyle={{
                            rootStyle: {
                                width: "100%"
                            },
                            inputStyle: {
                                padding: '0px'
                            },
                            placeholderStyle: {
                                color: '#8e90a2'
                            }
                        }} name="lastName" />
                        {errorState.lastName.isError && <InputError errorState={errorState} inputId="lastName" showIcon={true}/>}
                </Grid>
                <Grid item md={9} xs={12} className={classes.gridLowMargin}>
                    <label className={classes.labelStyle} >
                        Date of birth
                    </label>
                </Grid>
                <Grid item md={9} xs={12} className={[gridSeparation1+" "+myinput+(errorState.month.isError ? " error" : ""), classes.dateOfBirth]}>
                    <Grid item xs={4}>
                        <Dropdown
                            id="month"
                            data-testid="UserInfo-month"
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                    paddingRight: "5px",

                                }
                            }}
                            value={formValues.month}
                            ariaRequired={true}
                            onChange={e => changeHandler("month", e)}
                            errors={[errorState.month, errorState.dob]}
                            callouts={true}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            dropdownItems={buildDropDownMonth()}
                            placeholder="Month"
                            name='month' />
                            {errorState.month.isError && <InputError errorState={errorState} inputId="month" showIcon={true}/>}
                    </Grid>
                    <Grid xs={4}>
                        <Dropdown
                            id="day"
                            data-testid="UserInfo-day"
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                    paddingRight: "5px"
                                }
                            }}
                            value={formValues.day}
                            required={true}
                            dropdownItems={buildDropDownDay(formValues.month, formValues.year)}
                            errors={[errorState.day, errorState.dob]}
                            onChange={e => changeHandler("day", e)}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            placeholder="Day"
                            name='day' />
                            {errorState.day.isError && <InputError errorState={errorState} inputId="day" showIcon={true}/>}
                    </Grid>
                    <Grid xs={4}>
                        <Dropdown
                            id="year"
                            data-testid="UserInfo-year"
                            customStyle={{
                                rootStyle: {
                                    width: "100%"
                                },
                            }}
                            ariaRequired={true}
                            value={formValues.year}
                            onChange={e => changeHandler("year", e)}
                            errors={[errorState.year, errorState.dob]}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            dropdownItems={buildDropDownYear()}
                            placeholder="Year"
                            name='year' />
                            {errorState.year.isError && <InputError errorState={errorState} inputId="year" showIcon={true}/>}
                    </Grid>
                </Grid>                
                {
                    errorState.dob.isError && 
                    <Grid item md={9} xs={12} className={classes.gridSeparation}>
                        <span className={classes.errorLabel}>
                            {errorState.dob.errMsg}
                        </span>
                    </Grid>
                }

                <Grid >
                    <label htmlFor='lastSixDigitSSN' className={classes.labelStyle} >
                        <b>Last 6 digits</b> of your social security number
                    </label>
                </Grid>
                <Grid item md={9} xs={12} className={gridSeparation1+" "+myinput+(errorState.lastSixDigitSSN.isError && !errorState.lastSixDigitSSN.warning ? " error" : "")}>
                    <p className={classes.InLineLabel}>XXX - </p>
                    <div className={classes.InLine}>
                        <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                            id="lastSixDigitSSN"
                            data-testid="UserInfo-lastSixDigitSSN"
                            type={allValues.ssnVisible ? "text" : "password"}
                            required={true}
                            placeholder="XX XXXX"
                            mask={allValues.ssnVisible ? "99 9999" : "999999"}
                            value={formValues.lastSixDigitSSN}
                            onKeyDown={doNothing}
                            callouts={true}
                            onChange={e => changeHandler("lastSixDigitSSN", e)}
                            onBlur={()=> setErrorState({...errorState, lastSixDigitSSN: { ...errorState.lastSixDigitSSN, warning: false }})}
                            customStyle={{
                                rootStyle: {
                                    width: "120px",
                                }
                            }} name="lastSixDigitSSN"
                            isIconClickable={true}
                            icon={<ActionButton
                                ariaLabel="Edit"
                                icon={allValues.ssnVisible ? <Eye2 color="#282B3E" />
                                    : <Eye1 color="#282B3E" />
                                }
                                onClick={e => handleShow("ssnVisible")}
                            />}
                         />
                    </div>
                    
                    {errorState.lastSixDigitSSN.isError && <InputError errorState={errorState} inputId="lastSixDigitSSN" showIcon={true}/>}
                </Grid>
                <Grid item md={9} xs={12} className={gridSeparation1+" "+myinput+(errorState.emailAddress.isError && !errorState.emailAddress.warning ? " error" : "")}>
                    <label className={classes.labelStyle}>
                        Email address
                    </label>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id="emailAddress"
                        data-testid="UserInfo-emailAddress"
                        placeholder="Enter email address"
                        required={true}
                        maxLength={50}
                        icon={<Progress 
                            doShow={isEmailLoading}
                            isFloating={false} 
                            customStyle={{
                            spinnerStyle: { 
                                height: "25px",
                                width: "25px",
                                marginRight: "10px"
                            }
                        }} />}
                        value={formValues.emailAddress}
                        onKeyDown={doNothing}
                        onChange={e => changeHandler("emailAddress", e)}
                        callouts={true}
                        onBlur={event => verifyEmail()}
                        customStyle={{
                            rootStyle: {
                                width: "100%"
                            },
                            inputStyle: {
                                padding: '0px',
                                fontSize: "14px"
                            }
                        }} name="emailAddress" />
                        {errorState.emailAddress.isError && <InputError errorState={errorState} inputId="emailAddress" showIcon={true}/>}
                </Grid>
                <Grid item xs={12} className={gridSeparation1+" "+myinput+(errorState.phoneType.isError ? " error" : "")}>
                    <label htmlFor='phoneType' className={classes.labelStyle} >
                        Preferred method of contact<br />The entered phone number will be used for text/call verification.
                    </label>
                    <RadioGroup
                        id="phoneType"
                        data-testid="UserInfo-phoneType"
                        name="phoneType"
                        required={true}
                        onChange={e => changeHandler("phoneType", e)}
                        errors={[errorState.phoneType]}
                        errorCallout={errorState.phoneType}
                        selected={formValues.phoneType}
                        customStyle={
                            {
                                labelStyle: {
                                    fontSize: '14px',
                                    fontWeight: "400"
                                },
                                rootStyle: {
                                    paddingRight: "32%"
                                }
                            }
                        }
                        values={[
                            {
                                label: 'Home phone',
                                value: 'Home'
                            },
                            {
                                label: 'Mobile phone',
                                value: 'Mobile'
                            }
                        ]}
                    />
                    {errorState.phoneType.isError && <InputError errorState={errorState} inputId="phoneType" showIcon={true}/>}
                </Grid>
                <Grid item md={5} xs={12} className={gridSeparation1+" "+myinput+(errorState.phoneNumber.isError && !errorState.phoneNumber.warning ? " error" : "")}>
                    <label htmlFor='phoneNumber' className={classes.labelStyle} >
                        Phone number
                    </label>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id="phoneNumber"
                        data-testid="UserInfo-phoneNumber"
                        required={true}
                        placeholder="(XXX) XXX-XXXX"
                        mask="(999) 999-9999"
                        value={formValues.phoneNumber}
                        onKeyDown={doNothing}
                        callouts={true}
                        onChange={e => changeHandler("phoneNumber", e)}
                        onBlur={()=> setErrorState({...errorState, phoneNumber: { ...errorState.phoneNumber, warning: false }})}
                        customStyle={{
                            rootStyle: {
                                width: "148px",
                            },
                            inputStyle: {
                                padding: '0px'
                            }
                        }} name="phoneNumber" />
                        {errorState.phoneNumber.isError && <InputError errorState={errorState} inputId="phoneNumber" showIcon={true}/>}
                </Grid>
                
                {checkAppId && 
                <Grid item md={9} xs={12} className={[gridSeparation1+" "+myinput+((errorState.goPaperless.isError || errorState.notGoPaperless.isError) ? " error" : ""), classes.gridAlignment]}>
                    
                    <div className={classes.goPaperlessBody}>
                        <div className={classes.goPaperlesshead}>
                         <span className={classes.goPaperLessHeading}>Electronic Delivery</span>
                         
                          <span><Tooltip title={tooltipTextElectronicDelivery} bubbleWidth={290}
                            customStyle={{
                                rootStyle: {
                                    paddingLeft: "5px",
                                    marginTop: "-5px"
                                }
                            }}
                        ><Info1 color="#282B3E" />
                        </Tooltip></span>
                        
                        </div>
                        
                    <div className={classes.goPaperlessContentBody}>
                        <div className={[classes.goPaperLessSubHeadingBody]}>
                        <span className={classes.goPaperLessSubHeading}>
                        <i className={classes.logoStyles}>
                            <LeafLogo/>
                        </i>Paperless delivery is the fastest and more secure way to access key policy documents.</span>
                        </div>
                         <span className={classes.goPaperLessContent}>By selecting ‘Yes, go paperless’ you agree to receive emails with hyperlinks and files from John Hancock when a communication is delivered to your account, and confirm that you have read, understand, and agree to  the <a className={classes.blueUnderline} rel="noopener noreferrer" href="#" onClick= {(event) => paperlessModalOpen(event) }  target="_self" >Terms & Conditions of Electronic Delivery</a>. <b>This applies to all communications, notices, and disclosures related to all John Hancock life and/or long–term care insurance policies you own, except tax documents and Vitality communications.</b> If you would like to make changes or customize your paperless elections, you can update your settings by visiting the preferences page for each life and/or long–term care insurance policy after logging into your account.</span>
                 
                    </div> 
                    

                    <div className={classes.termsAndConditions} style={{fontWeight: "600", marginTop: "21px"}}>
                        <Checkbox
                            id="goPaperless"
                            data-testid="UserInfo-goPaperless"
                            required={true}
                            customStyle={{
                            }}
                            checked={formValues.goPaperless}
                            errors={[errorState.goPaperless]}
                            onChange={e => { changeHandler("goPaperless", e);}}
                            name="goPaperless"
                        />
                        <div className={classes.waiverText}><label htmlFor='goPaperless'>Yes, go paperless</label></div>
                    </div>
                        <div className={classes.termsAndConditions}>
                        <Checkbox
                            id="notGoPaperless"
                            data-testid="UserInfo-notGoPaperless"
                            required={true}
                            customStyle={{
                            }}
                            checked={formValues.notGoPaperless}
                            errors={[errorState.notGoPaperless]}
                            onChange={e => { changeHandler("notGoPaperless", e)}}
                            name="notGoPaperless"
                            
                        />
                        <div className={classes.waiverText}><label htmlFor='notGoPaperless'>No, I prefer to receive paper mail</label></div>


                    </div>
                    {
                        (errorState.goPaperless.isError || errorState.notGoPaperless.isError) &&
                        <InputError errorState={errorState} inputId="goPaperless" showIcon={true}/>
                    }
                    </div>
                </Grid>
                }

                <Grid item md={9} xs={12} className={gridSeparation1+" "+myinput+(errorState.wolChecked.isError ? " error" : "")}>
                    <div className={classes.tnCblock}>
                        {checkAppId &&
                            <div className={classes.termsandconditionsHeading}>Terms & Conditions</div>
                        }
                        <div className={classes.termsAndConditions}>
                        <Checkbox
                            id="wolChecked"
                            data-testid="UserInfo-wolChecked"
                            required={true}
                            customStyle={{
                            }}
                            checked={formValues.wolChecked}
                            errors={[errorState.wolChecked]}
                            onChange={e => changeHandler("wolChecked", e)}
                            name="wolChecked"
                        />
                        <div className={classes.waiverText}><label htmlFor='wolChecked'>By creating an account, I agree to John Hancock's <a className={classes.blueUnderline} rel="noopener noreferrer" href="https://www.johnhancock.com/legal.html" target="_blank" >Terms & Conditions</a>.</label></div>
                    </div>
                        {errorState.wolChecked.isError && <InputError errorState={errorState} inputId="wolChecked" showIcon={true}/>}
                    </div>
                </Grid>

                <Grid item md={12} xs={12} className={classes.ctaWrapper}>
                    {showSignIn && <a className={classes.signInUnderline} href={redirectUrl}><b>Sign in instead</b></a>}
                    <Button
                        name="next button"
                        data-testid="UserInfo-nextButton"
                        id="nextbutton"
                        onClick={e => validateAndConfirm(formValues, errorState, setErrorState)}
                        disabled={showSignIn || isAwaitingApiCall || isEmailLoading}
                    >Next</Button>
                </Grid>
            </Grid>
            <Modal
                isOpen={isModalOpen} 
                onClose={modalClose}
                shouldCloseOnBackdropClick={!isAwaitingApiCall}
                shouldCloseWithEscapeKey={!isAwaitingApiCall}
                customStyle={{
                modalStyle: {
                    height: 'auto',
                    width: '720px',
                    media: [{
                        maximum: BREAKPOINTS.PHONE_PORTRAIT_MED,
                        width: '85%'
                    }]
                }
            }} ariaLabel="Timeout Modal" ariaLabelledBy="heading" ariaDescribedBy="content">
                <ConfirmationModalPage formValues={formValues} />
                <Grid item xs={12} className={classes.buttonWrapper}>
                    <Button id="EditButton"
                        data-testid="UserInfo-EditButton"
                        name="EditButton"
                        variant={BUTTON_VARIANT.SECONDARY}
                        disabled={isAwaitingApiCall}
                        onClick={modalEdit}>Edit</Button>

                    <Button id="ConfirmButton"
                        data-testid="UserInfo-ConfirmButton"
                        name="ConfirmButton"
                        disabled={isAwaitingApiCall}
                        saving={isAwaitingApiCall}
                        onClick={modalConfirm}>Confirm</Button>
                </Grid>
            </Modal> 
                

               
         <Modal isOpen={isPaperlessTCModalOpen} onClose={paperlessModalClose} customStyle={{
                 modalStyle: {
                    height: 'auto',
                    width: '720px',
                    media: [{
                        maximum: BREAKPOINTS.PHONE_PORTRAIT_MED,
                        width: '85%'
                    }]
                }
            }} ariaLabel="Timeout Modal" ariaLabelledBy="heading" ariaDescribedBy="content">
                <ElectronicTCModalPage />
                <Button id="IUnderstandButton"
                        data-testid="UserInfo-IUnderstandButton"
                        name="IUnderstandButton"
                        customStyle={{
                            buttonStyle: {
                                width: "150px",
                                height: "60px"
                            }
                        }} disabled={isAwaitingApiCall} onClick= { e => checkisUnderstand(e)}><div className={classes.confirmTextStyle}>I understand</div></Button>                 

            </Modal>         
           
        </div>
    )
}

export default UserInfoPage